<template>
  <!--  我的运单-->
  <div class="myOrderList">
    <div>
      <div style="width: 60%;margin:30px auto;" shadow="never">
        <el-button type="primary" :plain="selectA" @click="clickA">我的运单</el-button>
        <el-button type="primary" :plain="selectB" @click="clickB">待开单</el-button>
      </div>

      <el-card style="width: 60%;margin:10px auto;line-height: 60px;" v-show="selectA==false">
        <div class="pull-left" style="display: flex">
          <div>
            <label class="label-font-down">开单时间</label>
            <el-date-picker
                size="mini"
                v-model="date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                :default-time="['00:00:00','23:59:59']"
                end-placeholder="结束日期"
                value-format="timestamp"
            >
            </el-date-picker>
          </div>
          <div >
            <label class="label-font-down">运单编号</label>
            <el-input type="text" size="mini" v-model="queryStr" style="width: 150px"/>
          </div>
          <div style="margin-left: 15px;">
            <el-radio-group v-model="tabId">
              <el-radio :label="1">我的发货</el-radio>
              <el-radio :label="2">我的收货</el-radio>
            </el-radio-group>
          </div>

        </div>
        <div class="pull-right">
          <el-button  size="mini" type="primary" @click="query">查询</el-button>
        </div>

      </el-card>
<div style="justify-content: space-around;width: 100%;">


      <!--    我的运单-->
      <el-row style="width: 60%;margin: auto" v-show="selectA==false" v-loading="loading"  :class="payment.length === 0 ? 'pic' : ''">
        <el-col :span="spanLine" v-for="item in payment" :key="item.index" >
          <el-card class="box-card" style="height:330px;cursor: pointer"  @click.native="toDetail(item)">
            <div slot="header" class="clearfix">
              <span>运单号：{{item.orderNo}}</span>
              <el-button style="float: right; padding: 3px 0" type="text" >{{item.messagesStatus}}</el-button>
            </div>
            <div style="margin:5px 0">运达部门：
              <span class="text-blue" v-if="isTable == true">{{item.receiveOrgName}}</span>
              <span v-else>{{item.receiveOrgName}}</span>
            </div>
            <div style="margin:10px 0" >收揽部门：
              <span class="text-blue" v-if="isTable == false">{{item.deliveryOrgName}}</span>
              <span v-else>{{item.deliveryOrgName}}</span>
            </div>
            <div style="margin:10px 0" >收揽时间：
              <span>{{todateTimelocal(item.createTime)}}</span>
            </div>
            <div style="margin-top:15px;display: flex;align-items: center;">
              <img src="../assets/img/fa.png"  style="width:28px;height: 28px;margin-right: 3%;"/>
              <div style="display: flex;flex-direction: column">
                <div style="display: flex">
                  <p style="margin-right: 3%">{{item.deliveryUserName}}</p>
                  <p>{{item.deliveryUserPhone}}</p>
                </div>
                <span class="addressText">{{item.deliveryAddress}}</span>
              </div>
            </div>
            <div style="margin-top:15px;display: flex;align-items: center;">
              <img src="../assets/img/shou.png"  style="width:28px;height: 28px;margin-right: 3%"/>
              <div style="display: flex;flex-direction: column">
                <div style="display: flex">
                  <p style="margin-right: 3%">{{item.receiveUserName}}</p>
                  <p>{{item.receiveUserPhone}}</p>
                </div>
                <span class="addressText">{{item.receiveAddress}}</span>
              </div>
            </div>
            <div style="display: flex;margin:10px auto">
              <div style="padding-left:5px;padding-right:5px;align-items: center;height: 20px;background: #ffffff;border: 1px solid #e5e5e5;border-radius: 4px;">{{item.goodsName}}</div>
              <div style="margin-left:10px;padding-left:5px;padding-right:5px;align-items: center;height: 20px;background: #ffffff;border: 1px solid #e5e5e5;border-radius: 4px;">{{item.goodsCount}}件货</div>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <!--    待开单-->
      <el-row style="width: 60%;margin: auto" v-show="selectB==false" v-loading="loading"  :class="orderData.length === 0 ? 'pic' : ''">
        <el-col :span="spanLine" v-for="item in orderData" :key="item.index">
          <el-card class="box-card" style="height: 280px;cursor: pointer;" @click.native="toDetail2(item)">
            <div slot="header" class="clearfix">
              <span>开单号：{{item.orderNumber}}</span>
              <el-button style="float: right; padding: 3px 0" type="text"  >待开单</el-button>
            </div>
            <div style="margin:10px 0" >开单时间：
              <span class="text-blue" >{{todateTimelocal(item.createTime)}}</span>
            </div>
            <div style="margin-top:15px;display: flex;align-items: center;">
              <img src="../assets/img/fa.png"  style="width:28px;height: 28px;margin-right: 3%;"/>
              <div style="display: flex;flex-direction: column">
                <div style="display: flex">
                  <p style="margin-right: 3%">{{item.deliveryName}}</p>
                  <p>{{item.deliveryPhone}}</p>
                </div>
                <span class="addressText">{{item.proName}} {{item.cityName}} {{item.areaName}} {{item.address}}</span>
              </div>
            </div>
            <div style="margin-top:15px;display: flex;align-items: center;">
              <img src="../assets/img/shou.png"  style="width:28px;height: 28px;margin-right: 3%"/>
              <div style="display: flex;flex-direction: column">
                <div style="display: flex">
                  <p style="margin-right: 3%">{{item.receiveName}}</p>
                  <p>{{item.receivePhone}}</p>
                </div>
                <span class="addressText">{{item.receiveProName}} {{item.receiveCityName}} {{item.receiveAreaName}} {{item.receiveAddress}}</span>
              </div>
            </div>
            <div style="display: flex;margin:10px auto">
              <div style="padding-left:5px;padding-right:5px;align-items: center;height: 20px;background: #ffffff;border: 1px solid #e5e5e5;border-radius: 4px;">{{item.goodName}}</div>
              <div style="margin-left:10px;padding-left:5px;padding-right:5px;align-items: center;height: 20px;background: #ffffff;border: 1px solid #e5e5e5;border-radius: 4px;">{{item.unit}}件货</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
</div>
    </div>
  </div>
</template>

<script>


import {findMyWayBill,findMyReceiveOrder,findMyOrder} from "@/api/myOrderList";
import { findOrderDetail } from "@/api/orderInfo";

export default {
  components: {

  },
  data() {
    return {
      loading: false,
      isTable:true,
      selectA:false,
      selectB: true,
      payment:[],//我的运单列表
      tabId:1,
      queryStr:'',//运单号
      date:[this.toTimeStamp(this.firstDay()), this.getEndDayTime()],
      orderData:[],//待开单列表
      id:'',
      orderInfo:'',
      spanLine:8
    };
  },
  methods:{
    //日期格式转时间戳
    toTimeStamp(times) {
      if (times === '' || times === null || !times) {
        return '';
      }
      let dates = times.split("-"),
          datex = new Date(dates[0], dates[1] - 1, dates[2], 0, 0, 0);
      return datex = Date.parse(datex);
    },
    //当月第一天日期
    firstDay() {
      let date_ = new Date();
      let year = date_.getFullYear();
      let month = date_.getMonth() + 1;
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      let firstDate = year + '-' + month + '-01';
      return firstDate
    },
    //当天的结束时间戳
    getEndDayTime() {
      var date = new Date();
      var todayYear = date.getFullYear();
      var todayMonth = date.getMonth();
      var today = date.getDate();
    //获取当天的开始时间
      new Date(todayYear, todayMonth, today, '00', '00', '00');
    //获取当天的结束时间
      return new Date(todayYear, todayMonth, today, '23', '59', '59').getTime();
    //包括当前时间，三天内
    },
    //我的运单详情
    toDetail(val){
      let queryParams = {
        search: this.queryStr,
        tabId: this.tabId,
        startTime: this.date[0],
        endTime: this.date[1]+999,
        selectA:this.selectA,
        selectB:this.selectB,
      };
      sessionStorage.setItem('myOrderList',JSON.stringify(queryParams));
      this.orderInfo = val;
      this.$router.push({ name: 'orderInfo' ,params: { data: val }})
    },
    //待开单详情
    toDetail2(val){
      let queryParams = {
        search: this.queryStr,
        tabId: this.tabId,
        startTime: this.date[0],
        endTime: this.date[1]+999,
        selectA:this.selectA,
        selectB:this.selectB,
      };
      sessionStorage.setItem('myOrderList',JSON.stringify(queryParams));
      console.log(123)
      this.$router.push({ name: 'netOrderInfo' ,params: { data: val }})
    },
    optionsPackaging(value) {
      if (!value) return '';
      if (value === 1) {
        value = "纸箱"
      } else if (value === 2) {
        value = "编织袋"
      } else if (value === 3) {
        value = "泡沫箱"
      } else if (value === 4) {
        value = "木箱"
      } else if (value === 5) {
        value = "塑料袋"
      } else if (value === 6) {
        value = "回单"
      } else if (value === 7) {
        value = "无"
      } else if (value === 8) {
        value = "纸"
      } else if (value === 9) {
        value = "袋"
      } else if (value === 10) {
        value = "桶"
      } else if (value === 11) {
        value = "木"
      } else if (value === 12) {
        value = "膜"
      } else if (value === 13) {
        value = "托盘"
      }
      return value;
    },
    // 默认选中按钮事件
    clickA() {
      this.selectA = false;
      this.selectB = true;
    },
    // 按钮事件
    clickB() {
      this.selectA = true;
      this.selectB = false;
      this.query()
    },
    //时间戳转日期＋时间
    todateTimelocal(timeStamp) {
      if (timeStamp === '' || timeStamp === null || timeStamp === undefined || timeStamp === '0' || timeStamp === 0) return '';
      var date = new Date();
      date.setTime(timeStamp);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      h = h < 10 ? ('0' + h) : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? ('0' + minute) : minute;
      second = second < 10 ? ('0' + second) : second;
      return timeStamp = y + '-' + m + '-' + d + '   ' + h + ':' + minute + ':' + second;
    },
    query(){
      if(this.date == null){
        this.$message({
          message: "请选择开单时间",
          type: "error",
          offset: 80,
        });
      }else{
        this.loading = true;
        if(this.tabId==1 && this.selectA == false){
          this.getDeliveryList();
          this.isTable = true;
        }
        if(this.tabId==2 && this.selectA == false){
          this.getReceiveList();
          this.isTable = false;
        }
        if(this.selectA == true && this.selectB == false){
          this.getOrderList()
        }
      }
    },
    //我的运单-我的发货
    getDeliveryList(){
      if(this.date == null){
        this.date = []
      }
      let params = {
        search: this.queryStr,
        startTime: this.date[0],
        endTime: this.date[1]+999,
      }
      findMyWayBill(params).then((res) => {
        if (res.code === 200) {
          if(res.data.list.length == 0){
            this.$message({
              message: "未查到数据",
              type: "success",
              offset: 80,
            });
          }
          this.payment = res.data.list
          this.payment.forEach(item=>{
            Object.assign(item,{routeName:1})
          })
          this.loading = false;
        }
      })
    },
    //我的运单-我的收货
    getReceiveList(){
      let params = {
        search: this.queryStr,
        startTime: this.date[0],
        endTime: this.date[1]+999,
      }
      findMyReceiveOrder(params).then((res) => {
        if (res.code === 200) {
          if(res.data.list.length == 0){
            this.$message({
              message: "未查到数据",
              type: "success",
              offset: 80,
            });
          }
          this.payment = res.data.list
          this.payment.forEach(item=>{
            Object.assign(item,{routeName:2})
          })
          this.loading = false;
        }
      })
    },
    //查询待开单
    getOrderList(){
      let params = {
        type: 1
      }
      findMyOrder(params).then((res) => {
        if (res.code === 200) {
          if(res.data.list.length == 0){
            this.$message({
              message: "未查到数据",
              type: "success",
              offset: 80,
            });
          }
          this.orderData = res.data.list;
          this.loading = false;
        }
      })
    },
  },
  mounted() {
    // if(this.$route.params.data){
    //   this. clickB();
    // }
    if(sessionStorage.getItem('myOrderList')){
      let queryParams = JSON.parse(sessionStorage.getItem('myOrderList'));
      this.queryStr= queryParams.queryStr;
      this.tabId= queryParams.tabId;
      // this.startTime= queryParams.startTime;
      // this.endTime= queryParams.endTime;
      this.selectA=queryParams.selectA;
      this.selectB=queryParams.selectB;
      this.date=[queryParams.startTime, queryParams.endTime];
      this.query();
      sessionStorage.removeItem('myOrderList');
    }
    this.getDeliveryList()
    function isMobileOrTablet() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(userAgent)
              && !/1237|6000f|i853|i855|i856|i857|i858|i860|i861|i862|i863|i864|i865|i939|j300|j500|j700|n300|n302|n303|n500|n502|n505|n700|n701|n710|nec-microwave|nec-sw|nec-vz|pg|playstation portable|pocket pc|pocket pc 2.0|pocket pc 2.10|pocket pc 2.20|pocket pc 2.30|pocket pc 2.50|pocket pc paint|sharp|sie-|softbank|sony ericsson|sonyericsson|sph-|symbian|symbianos|symbian v9|wap|windows ce|windows phone|xda|zte/i.test(userAgent);
    }

    if (isMobileOrTablet()) {
      this.spanLine = 24;
      console.log('H5（移动端）');
    } else {
      this.spanLine = 8;
      console.log('PC');

    }
  }
};
</script>

<style scoped>
*{font-size: 14px}
.addressText{
  display: -webkit-box;
  color: #2b3f69;
  opacity: 0.6;overflow: hidden;
  /*white-space: nowrap;*/
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.pull-left{float:left}
.pull-right{float: right}
.text-blue{color: #409EFF}
.label-font-down {
  margin: 5px 8px;
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Normal;
  font-weight: normal;
  text-align: LEFT;
  color: #333333;}

.orderText {
  color: white;
  border-radius: 50%;
  height: 33px;
  width: 33px;
  text-align: center;
  line-height: 33px;
  margin-right:3%;
}
.box-card {
  width: 95%;
  padding-bottom: 10px;
  margin:10px 0;

}
.bold-text{
  font-size: 18px;
  font-weight: bold;
}
.pic{
  margin:0 auto;
  background-position:center;
  background-size:240px 180px;
  background-image: url("../assets/img/kong.png");
  text-align: center;
  width: 300px;height: 300px;
  background-color: #ffffff;border-radius: 8px;
  background-repeat: no-repeat;
}
</style>
